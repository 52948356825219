import React from "react";
import Helmet from "react-helmet";
import {metaData} from "../../config"
import OGGrootan from "../../img/og-grootan.jpg";
const MetaData = ({ pageTitle, description, keyword }) => {
  return (
    <Helmet htmlAttributes={{lang: 'en'}}>
      <meta charset="utf-8" />
      <meta content="IE=edge" http-equiv="X-UA-Compatible" />
      <meta content="on" http-equiv="cleartype" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="OAuth2 and Cloud Solutions & Grootan | Grootan Technologies" />
      <meta property="og:url" content="https://www.grootan.com/" />
      <meta property="og:image" content={`https://www.grootan.com${OGGrootan}`} />
      <meta property="og:description" content="Grootan - a Global IT leader, expertise in blending insights and strategy to develop cutting edge digital products for forward-thinking organisations" />      
      <link rel="canonical" href="https://www.grootan.com/" />
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
      <meta name="author" content="Grootan Technologies" />
      <meta
        name="keywords"
        content= {keyword ? keyword : metaData.home.keyword }
      />
      <meta
        name="google-site-verification"
        content="d_PSwy9nlNnndaOCsx2SRR-hE74DcEiRb-GdxUhF2Hc"
      />
      <meta
        name="google-site-verification"
        content="kpsfr8wD_Z951jy8f1FnP_Qn5JlSEJQvdXSkyrkjSIs"
      />
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1"
      />
      <meta
        name="description"
        content={description ? description : metaData.home.description }
      />
      <meta name="robots" content="index, follow" />
      <title>
        {pageTitle.indexOf(" | Grootan Technologies") > -1
          ? pageTitle
          : `${pageTitle} | Grootan Technologies`}
      </title>
    </Helmet>
  );
};

export default MetaData;
